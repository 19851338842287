<template>
  <div>
    <b-row>
      <b-col cols="4" v-for="(training, i) in trainings" :key="i">
        <rm-training-card :training="training" />
      </b-col>
      <b-col cols="4">
        <div
          @click="globalRouter('training.new')"
          class="pointer d-flex justify-content-center align-items-center training-add border border-success rounded-md h-100 bg-success--light"
        >
          <div
            class="d-flex align-items-center flex-column justify-content-center text-center"
          >
            <div>
              <b-icon class="text-success" icon="plus" scale="4.4"></b-icon>
            </div>
            <div class="text-md-2 text-success mt-3">
              Add New <br />Training
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RmTrainingCard from "./components/RmTrainingCard.vue";

export default {
  components: {
    RmTrainingCard,
  },
  data: () => ({
    trainings: [
      {
        banner:
          "https://images.unsplash.com/photo-1582407947304-fd86f028f716?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.training-add {
  min-height: 14rem;
}
</style>
