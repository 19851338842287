var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"training-card bg-white rounded-md overflow-hidden",on:{"click":function($event){return _vm.globalRouter('training.details', { id: 1 })}}},[_c('div',{staticClass:"training-card__banner pointer w-100",style:({
      backgroundImage: `url(${_vm.training.banner})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundColor: 'var(--primary)',
      height: '17rem',
    })}),_c('div',{staticClass:"training-card__details p-4 h-100"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_vm._m(0),_c('div',{staticClass:"pointer bg-gray-500 training-card__edit text-sm-2 rounded-circle d-flex justify-content-center align-items-center"},[_c('b-icon',{attrs:{"icon":"pencil-square"}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-sm font-weight-bold text-white"},[_vm._v(" How to market in 7 steps ")]),_c('div',{staticClass:"text-sm mt-2 text-gray-500"},[_vm._v("Audio, Video, Transcript")])])
}]

export { render, staticRenderFns }