<template>
  <div
    @click="globalRouter('training.details', { id: 1 })"
    class="training-card bg-white rounded-md overflow-hidden"
  >
    <div
      class="training-card__banner pointer w-100"
      :style="{
        backgroundImage: `url(${training.banner})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: 'var(--primary)',
        height: '17rem',
      }"
    />
    <div class="training-card__details p-4 h-100">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <div class="text-sm font-weight-bold text-white">
            How to market in 7 steps
          </div>
          <div class="text-sm mt-2 text-gray-500">Audio, Video, Transcript</div>
        </div>
        <div
          class="pointer bg-gray-500 training-card__edit text-sm-2 rounded-circle d-flex justify-content-center align-items-center"
        >
          <b-icon icon="pencil-square"></b-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    training: {
      type: Object,
      required: true,
    },
    isProperty: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      // if (!this.isProperty) {
      // 	this.globalRouter("properties", { trainingId: 1 });
      // } else {
      // 	this.globalRouter("properties.details", { propertyId: 1 });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.training-card {
  min-height: 14rem;

  &__details {
    background: rgba(11, 12, 12, 1);
    backdrop-filter: blur(14px);
  }

  &__edit {
    height: 2rem;
    width: 2rem;
  }
}
</style>
